import React from 'react';
import Image from 'next/image';

function createImageIconComponent(src) {
  const BenefitIcon = (props) => (
    <Image alt="" height={50} width={50} src={src} {...props} />
  );
  return BenefitIcon;
}

const BenefitIcons = {
  ShefIcon: createImageIconComponent('/artifacts/images/hc-shef-icon.webp'),
  NotebookIcon: createImageIconComponent(
    '/artifacts/images/hc-notebook-icon.webp'
  ),
  CustomerIcon: createImageIconComponent(
    '/artifacts/images/hc-customer-icon.webp'
  ),
  CheckmarkIcon: createImageIconComponent(
    '/artifacts/images/hc-checkmark-icon.webp'
  ),
  FiveStarIcon: createImageIconComponent(
    '/artifacts/images/hc-5-star-icon.webp'
  ),
};

export default BenefitIcons;
