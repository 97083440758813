import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import { TextField, TextFieldProps } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CateringCategoryValue } from 'qualification/schema/questions/cateringCategoriesQuestion';
import { persistCityInLocalStorage } from 'shared/helpers/cities';
import useCities from 'shared/hooks/useCities';
import { useLocalizeCity } from 'shared/hooks/useLocalizeCity';
import { buildFunnelUrl } from 'static_pages/marketing_pages/helpers';

interface Props {
  cityKeyContentful?: string;
  cateringCategories?: CateringCategoryValue[];
  width?: string;
  buttonLabelKey?: string;
}

function CitySelector({
  cityKeyContentful,
  cateringCategories,
  buttonLabelKey = 'common:labels.findCaterers',
}: Props) {
  const router = useRouter();
  const { t } = useTranslation('static_pages');
  const { cities, cityNames, cityKeys } = useCities();
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const localizeCity = useLocalizeCity();
  const handleRedirect = (city: string) => {
    if (city === 'berlin') {
      window.location.href = buildFunnelUrl({
        city: city,
        cateringCategories,
        router,
      });

      return;
    }

    persistCityInLocalStorage(city);
    router.push(
      buildFunnelUrl({
        city,
        cateringCategories,
        router,
      })
    );
  };

  const handleAutocompleteSelection = (event: unknown, city: unknown) => {
    const cityKey = cityKeys.find((cityKey) => cities[cityKey] === city);

    if (cityKey) {
      handleRedirect(cityKey);
    }
  };

  const handleFindCaterersSelection = () => {
    handleRedirect(autocompleteValue?.toLowerCase());
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: string) => option,
  });

  useEffect(() => {
    if (cityKeyContentful) {
      const localizedCity = localizeCity(cityKeyContentful);

      if (localizedCity) {
        setAutocompleteValue(cityKeyContentful);
        setInputValue(localizedCity);
      }
    }
  }, [cityKeyContentful, localizeCity]);

  return (
    <CitySelectorWrapper>
      <TextFieldWrapper>
        <LocationIconWrapper>
          <LocationOnIcon color="secondary" />
        </LocationIconWrapper>
        <Autocomplete
          freeSolo
          autoComplete
          autoHighlight
          value={autocompleteValue}
          onChange={handleAutocompleteSelection}
          options={cityNames}
          inputValue={inputValue}
          filterOptions={filterOptions}
          renderOption={(option) => (
            <Typography
              variant="body1"
              fontWeight={500}
              id={`city-selector-${option}`}
            >
              {option}
            </Typography>
          )}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              placeholder={t('common:labels.selectCity')}
              onChange={(event) => setInputValue(event.target.value)}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          )}
        />
      </TextFieldWrapper>
      <TextFieldButtonWrapper>
        <TextFieldButton
          size="lg"
          onClick={handleFindCaterersSelection}
          aria-label={t(buttonLabelKey)}
        >
          {t(buttonLabelKey)}
        </TextFieldButton>
      </TextFieldButtonWrapper>
    </CitySelectorWrapper>
  );
}

const TextFieldButtonWrapper = styled.div`
  width: 100%;
  padding-top: 10px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: absolute;
    padding: 0;
    width: auto;
    right: 0px;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
  }
`;

const CitySelectorWrapper = styled(Box)`
  margin: 0 auto;
  width: 100%;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: ${(props) => props.width};
    width: 100%;
    display: flex;
    box-shadow: 0 4px 40px rgba(62, 41, 29, 0.1);
    border-radius: 500px;
    position: relative;
    transition: ${(props) =>
      props.theme.transitions.create('box-shadow', {
        duration: '.2s',
      })};
    z-index: 1;
    &:hover {
      box-shadow: 0 4px 50px rgba(62, 41, 29, 0.15);
    }
  }
`;

const TextFieldWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const LocationIconWrapper = styled.div`
  position: absolute;
  left: 14px;
  top: 14px;
  z-index: 1;
`;

const TextFieldButton = styled(Button)`
  width: 100%;
  min-height: 52px; // Same as the input field
  padding: 11px 24px;

  border-radius: 100px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    border-radius: 0;
    min-height: 0px;
    height: 100%;
  }

  svg {
    font-size: 1rem;
  }
`;

const StyledTextField = styled(TextField)`
  background: white;
  padding: 12px ${(props) => props.theme.spacing(2)}px 12px
    ${(props) => props.theme.spacing(6)}px;
  border-radius: 500px;
  box-shadow: 0px 4px 40px rgba(62, 41, 29, 0.08);
` as React.ComponentType<TextFieldProps>;
export default CitySelector;
