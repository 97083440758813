import React from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import Image from 'next/image';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useBreakpointUp } from 'qualification/hooks/useBreakpoints';
import { createSizes } from 'shared/helpers/createSizes';
import { marketplaceURL } from 'shared/helpers/urls';
import BenefitsRow from 'static_pages/components/BenefitsRow';
import CitySelector from 'static_pages/components/CitySelector';
import Container, { ContainerSize } from 'system/components/Container';

const DEFAULT_IMAGE_SRC =
  '/artifacts/images/marketing_pages/food-group-cta-illustration.webp';

interface Props {
  virtualEvent?: boolean;
  cityFromSlug?: string;
  imageSrc?: string;
  containerSize?: ContainerSize;
}

export default function CTAWithBenefits({
  virtualEvent,
  cityFromSlug,
  imageSrc,
  containerSize = 'md',
}: Props) {
  const { t } = useTranslation('static_pages');

  const isTablet = useBreakpointUp('md');

  return (
    <>
      <BackgroundColorBox>
        <Container size={containerSize} disableGutters={!isTablet}>
          <Wrapper>
            <ContentWrapper>
              {virtualEvent ? (
                <>
                  <Typography variant="h3" mb={4} align="center">
                    {t('virtualEventsHero.title.a')}{' '}
                    <Typography variant="inherit" slab={true} color="secondary">
                      {t('virtualEventsHero.title.b')}
                    </Typography>
                  </Typography>
                </>
              ) : (
                <Title variant="h3" mb={4}>
                  <Trans
                    t={t}
                    i18nKey="footerCTA.title"
                    components={[<span key="span" />]}
                  />
                </Title>
              )}
              {virtualEvent ? (
                <Box display="flex" justifyContent="center">
                  <Button
                    size="lg"
                    component="a"
                    href={marketplaceURL('/catering/virtual_events')}
                  >
                    {t('common:labels.findVirtualEvents')}
                  </Button>
                </Box>
              ) : (
                <Box display="flex" mt={4} maxWidth={500} width="100%">
                  <CitySelector cityKeyContentful={cityFromSlug} />
                </Box>
              )}
            </ContentWrapper>
            <ImageWrapper>
              <Image
                alt=""
                height={278}
                width={425}
                layout="responsive"
                src={imageSrc || DEFAULT_IMAGE_SRC}
                sizes={createSizes({ xs: '100vw', sm: '400px' })}
              />
            </ImageWrapper>
          </Wrapper>
        </Container>
      </BackgroundColorBox>
      <BenefitsRow />
    </>
  );
}

const ContentWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 2.5, 8, 2.5)};
  max-width: 600px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: ${({ theme }) => theme.spacing(5, 3, 5, 0)};
  }
`;

const ImageWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing(3)}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(0, 0, 2, 0)};
    margin-left: auto;
    margin-right: auto;
    min-width: 425px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  gap: ${({ theme }) => theme.spacing(4)}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    gap: 0;
  }
`;

const BackgroundColorBox = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  background: ${(props) => props.theme.colors.heroGradient};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: ${({ theme }) => theme.spacing(2)}px;
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;
    padding-right: ${({ theme }) => theme.spacing(2)}px;
    padding-left: ${({ theme }) => theme.spacing(2)}px;
  }
`;

const Title = styled(Typography)`
  white-space: pre-line;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
`;
