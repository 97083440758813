import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import BenefitIcons from 'shared/components/BenefitIcons';
import FlexBox from 'shared/components/FlexBox';
import { BENEFITS } from 'shared/constants';
import Container, { ContainerSize } from 'system/components/Container';

interface Props {
  containerSize?: ContainerSize;
}

export default function BenefitsRow({ containerSize = 'md' }: Props) {
  const { t } = useTranslation('static_pages');

  return (
    <BenefitsWrapper>
      <Container size={containerSize}>
        <BenefitsLayout>
          {getBenefits(t).map(({ Icon, text }) => (
            <FlexBox alignItems="center" key={text} gap={2}>
              <Box flexShrink="0">{Icon}</Box>
              <Typography>{text}</Typography>
            </FlexBox>
          ))}
        </BenefitsLayout>
      </Container>
    </BenefitsWrapper>
  );
}

const BenefitsLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.spacing(2)}px;
  justify-items: start;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 0)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: repeat(2, auto);
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing(8)}px;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(2, 0)};
  }
`;
const BenefitsWrapper = styled.div`
  background-color: #fafaf8;
  padding: ${({ theme }) => theme.spacing(0, 2)};
`;

function getBenefits(t: (str: string, options?: unknown) => string) {
  return [
    {
      Icon: <BenefitIcons.NotebookIcon width="35px" height="35px" />,
      text: t('common:benefits.nr1'),
    },
    {
      Icon: <BenefitIcons.CustomerIcon width="35px" height="35px" />,
      text: t('common:benefits.customerCount', {
        count: '13.500',
      }),
    },
    {
      Icon: <BenefitIcons.ShefIcon width="35px" height="35px" />,
      text: t('common:benefits.catererCount', {
        count: BENEFITS.CATERER_COUNT,
      }),
    },
    {
      Icon: <BenefitIcons.FiveStarIcon width="40px" height="30px" />,
      text: t('common:benefits.satisfaction', {
        percent: BENEFITS.CUSTOMER_SATISFACTION_PERCENT,
      }),
    },
  ];
}
